import React, { forwardRef, useCallback } from 'react'
import { FormattedDate, FormattedTime, useIntl } from 'react-intl'
import { Link, useNavigate } from 'react-router-dom'
import { Button } from 'react-bootstrap'

import { Expandable } from '../../../../App/components/Expandable'

import { Block } from '../../Block'

import { Event as EventModel } from '../../../../../../cz.samaya.api/src/_api-models/index'

type CourseProps = {
    row: EventModel
    allowReservation?: boolean
}

const duration = ( item: EventModel ): string => {
    if( item.start && item.end ) {
        const duration = ((item.end.getHours() * 60) + item.end.getMinutes())
            - ((item.start.getHours() * 60) + item.start.getMinutes())
        const mins = Math.floor(duration % 60)
        const hours = Math.floor(duration / 60)

        return String( hours ).padStart( 2, '0' ) + ':' + String( mins ).padStart( 2, '0' )
    }
    return 'N/A'
}

export const DAYS: string[] = [
    'pondělí', 'úterý', 'středa', 'čtvrtek', 'pátek', 'sobota', 'neděle'
]

const decodeWDay = ( item: EventModel ): string => {
    if( !item.rrule_data || !item.rrule_data.wday ) {
        return ''
    }
    return item.rrule_data.wday.map( ( day ) => DAYS[ parseInt( day ) ] ).join( ',' )
}

export const Course = forwardRef<HTMLDivElement, CourseProps>(( { row, allowReservation = true }: CourseProps, ref ) => {

    const intl = useIntl()

    const navigate = useNavigate()

    let attn = ''
    row.attns.forEach( ( k ) => {
        attn += `<li><a href="/assets${ k.filename }" target="_self" download>${( k.name || k.filename )}</a></li>`
    } )
    if( attn !== '' ) {
        row.description += '<div><div>Přílohy:</div><ul>' + attn + '</ul></div>'
    }

    const dates: string[] = [ intl.formatDate( row.start ), ...(
        row.rrule_data && row.rrule_data.date ? row.rrule_data.date.map( d => intl.formatDate( new Date( d ) ) ) : []
    ) ]

    // force Míša as contact for reservations for all other that lectorId === 70
    if( ![ 70, 72, 73 ].includes( row.lectorId ) ) {
        row.lectorPhone = '+420 605 583 469'
        row.lectorEmail = 'michaela@samaya.cz'
    }

    const onReservation = useCallback( ( courseId: string ) => {
        navigate( '/order/' + courseId )
    }, [] )

    return(
        <Block ref={ ref } title={ ( ( row.name || '' ) + ( row.ext_data?.fullyBooked ? ' - vyprodáno' : '' ) ) }>
            <div className="text-box text-left">
                <blockquote className="blockquote">
                    { row.rrule === 'WDAY' && <div>Pravidelně: { decodeWDay( row ) } <FormattedTime value={ row.start } />-<FormattedTime value={ row.end } /></div> }
                    { row.rrule === 'DATE' && <div>
                        <div>Ve dnech: { dates.join( ', ' ) }</div>
                        <div>vždy: <FormattedTime value={ row.start } />-<FormattedTime value={ row.end } /></div>
                    </div> }
                </blockquote>
                <Expandable content={ row.description || '' } />
                <blockquote className="blockquote">
                    <div>Lektor: <Link to={ `/lektori#L${ row.lectorId?.toString() || '' }` }><strong>{ row.lectorName }</strong></Link></div>
                    { row.price && <>
                        { row.price.price && <div>Cena permanentky na kurz: { row.price.price }Kč</div> }
                        { row.price.pricekl && <div>Cena klouzavé permanentky: { row.price.pricekl }Kč</div> }
                        { row.price.priceah && <div>Cena za lekci bez permanentky: { row.price.priceah }Kč</div> }
                    </> }
                    <div>Délka: { duration( row ) }</div>
                    { row.rrule !== 'DATE' && <div>Platí pro období: <FormattedDate value={ row.start } /> - <FormattedDate value={ row.end } /></div> }
                    <div className="reserve p-2">Rezervace:
                        <div className="text-center">{ row.lectorPhone }</div>
                        <div className="text-center">nebo&nbsp;
                            { row.lectorEmail && <a href={ `mailto:${ row.lectorEmail }` }>{ row.lectorEmail }</a> }
                        </div>
                        { allowReservation && ! row.ext_data?.fullyBooked && ![ 70, 72, 73 ].includes( row.lectorId ) && <div className='text-center'>
                            <Button onClick={ () => onReservation( row.eventId || '' ) }>Rezervovat on-line</Button>
                        </div> }
                    </div>
                </blockquote>
            </div>
        </Block>
    )
} )